import React, { useState, useEffect, useRef } from 'react';
import styles from './Landing.module.css';
import { GlobeSearchFilled } from "@fluentui/react-icons";
import { FlipCard } from "../../components/FlipCard";
import { landingcard, previewFeatureSuffix } from "../../config/descriptions";
import { ShowPreviewFeatures } from "../../config/frontendconfig";
import { useTheme } from '../../state/themecontext';
import { TwoButtonDialog, TwoButtonDialogHandle, ErrorDialog, ErrorDialogHandle } from "../../components/Dialogs";
import { getCurrentVersion, disableAPI } from "../../api/api";
import { msalInstance } from "../../api/auth";

const Landing: React.FC = () => {
    const [showPreviewFeatures, setShowPreviewFeatures] = useState<boolean>(ShowPreviewFeatures === "true");
    const [isFlipped, setIsFlipped] = useState(Array(showPreviewFeatures ? 4 : 3).fill(false));
    const [reloadRequired, setReloadRequired] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(document.visibilityState === 'visible');
    const [hasFDPAccess, setHasFDPAccess] = useState<boolean>(false);
    const versionDialogRef = useRef<TwoButtonDialogHandle | null>(null);
    const errorDialogRef = useRef<ErrorDialogHandle | null>(null);
    const { themeColors } = useTheme();

    // update the local version to current App Version
    const updateCurrentVersion = async () => {
        try {
            const currentVersion : string = await getCurrentVersion();
            localStorage.setItem('appVersion', currentVersion);
        } catch (error) {
            // catch local storage overflow error
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }
    };
    // This will trigger upon reload and re-render! We have to be careful for the case when users navigate away and back again
    // Therefore, we do not enable the API here, but instead update the version and set reload required to false
    // so that if this was a reload the error dialog is disabled and local version updated.
    // If a re-render triggers this, the API will still be disabled until the user reloads, so it is ok to update the version preemptively.
    useEffect(() => {
        setReloadRequired(false);
        updateCurrentVersion();
    }, []);

    useEffect(() => {
        const checkRoles = async () => {
            const account = msalInstance.getActiveAccount();
            if (account) {
                const roles = account.idTokenClaims?.roles || [];
                if (roles.includes('CoopGPT.StructuredData')) {
                    setHasFDPAccess(true);
                } else {
                    setHasFDPAccess(false);
                }
            }
        };
        checkRoles();
    }, []);
    
    useEffect(() => {
        if (reloadRequired) {
            if (versionDialogRef.current) {
                versionDialogRef.current.handleClickOpen();
            }
        }
    }, [reloadRequired])

    useEffect(() => {
        function handleVisibilityChange() {
            setIsVisible(document.visibilityState === 'visible');
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {document.removeEventListener('visibilitychange', handleVisibilityChange)};
    }, []);

    useEffect(() => {
        if (isVisible) {
            compareVersion();
        }
    }, [isVisible]);

    const compareVersion = async () => {
        if (document.visibilityState === 'visible') {
            // check the version
            const currentVersion: String = await getCurrentVersion();
            const localVersion: String = localStorage.getItem('appVersion') || "undefined";
            if (currentVersion !== localVersion) {
                // alert the user
                setReloadRequired(true);
            }
        }
    }

    const landingStyle = {
        '--background-color': themeColors.backgroundColor,
        '--text-color': themeColors.cardDescriptionTextColor,
        '--cardstrip1-color': themeColors.cardStrip1Color,
        '--pagetitle-color': themeColors.landingPageTitleColor,
        '--card-color': themeColors.cardColor,
        '--disabled-card-color': themeColors.disabledCardColor,
        '--title-color': themeColors.cardTitleColor,
        '--fliptext-color': themeColors.cardFlipTextColor,
        '--background-image': themeColors.backgroundImage, 
        '--iconfill-color': themeColors.iconFillColor,
        '--description-hover-text-color': themeColors.cardDescriptionHoverTextColor,
        '--title-hover-text-color': themeColors.cardTitleHoverColor,
        '--fliptext-hover-color': themeColors.cardFlipHoverTextColor,
        '--card-link-text-color': themeColors.cardLinkTextColor
    } as React.CSSProperties;

    const handleFlip = (index: number) => (e: React.MouseEvent) => {
        e.stopPropagation();
        const newFlippedState = [...isFlipped];
        newFlippedState[index] = !newFlippedState[index];
        setIsFlipped(newFlippedState);
    };

    const modifyTextForPreview = (description: string): string => {
        if (showPreviewFeatures){
            return description;
        }else {
            return description + previewFeatureSuffix;
        }
    };

    const handleVersionUpdateRequired = (flag : boolean) => {
        if (flag) {
            window.location.reload();
        } else {
            disableAPI();
        }
    }

    const cardsData = [
        {
            title: landingcard.SafeGPT.title,
            description: landingcard.SafeGPT.description,
            backDescription: landingcard.SafeGPT.backDescription,
            navigateTo: {
                pathname: '/chat',
                state: { cardIdentifier: "safegpt2" }
            },
            cardStyle: styles.card1,
            isFlippedState: isFlipped[0],
            handleFlip: handleFlip(0),
            disabled: false
        },
        {
            title: landingcard.CoopGPTPPL.title,
            description: landingcard.CoopGPTPPL.description,
            backDescription: landingcard.CoopGPTPPL.backDescription,
            navigateTo: {
                pathname: '/chat',
                state: { cardIdentifier: "coopgptppl" }
            },
            cardStyle: styles.card2,
            isFlippedState: isFlipped[1],
            handleFlip: handleFlip(1),
            disabled: false
        },
        {
            title: modifyTextForPreview(landingcard.CoopGPTFDP.title),
            description: landingcard.CoopGPTFDP.description,
            backDescription: landingcard.CoopGPTFDP.backDescription,
            navigateTo: {
                pathname: '/chat',
                state: { cardIdentifier: "coopgptfdp" }
            },
            cardStyle: styles.card3,
            isFlippedState: isFlipped[2],
            handleFlip: handleFlip(2),
            disabled: !hasFDPAccess
        },
        ...(showPreviewFeatures ? [{
            title: landingcard.ImageGeneration.title,
            description: landingcard.ImageGeneration.description,
            backDescription: landingcard.ImageGeneration.backDescription,
            navigateTo: {
                pathname: '/chat',
                state: { cardIdentifier: "imagegen" }
            },
            cardStyle: styles.card4,
            isFlippedState: isFlipped[3],
            handleFlip: handleFlip(3),
            disabled: false
        }] : [])
    ];

    useEffect(() => {
        const initDataLayer = async () => {
            // Update the page title
            document.title = `Co-op GPT`;

            const account = msalInstance.getActiveAccount();
            const loginStatus = account ? 'logged in' : 'logged out';
            const userId = account?.homeAccountId || '';

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'page_init',
                user: {
                    id: userId,
                    loginStatus: loginStatus
                },
                page: {
                    id: 'landing',
                    type: 'landing',
                    url: window.location.href,
                    title: 'Co-op GPT | Landing'
                }
            });
        };

        initDataLayer();
    }, []);

    return (
        <div className={styles.container} style={landingStyle} data-preview-enabled={showPreviewFeatures}>
            <GlobeSearchFilled fontSize={"120px"} primaryFill={themeColors.iconFillColor} aria-hidden="true" aria-label="Landing logo" />
            <div className={styles.pageTitle}>Select a data source</div>
            <div className={styles.cardsContainer}>
                {cardsData.map((card, index) => (
                    <FlipCard
                        key={index}
                        title={card.title}
                        description={card.description}
                        backDescription={card.backDescription}
                        navigateTo={card.navigateTo}
                        cardStyle={card.cardStyle}
                        isFlipped={card.isFlippedState}
                        handleFlip={card.handleFlip}
                        disabled={card.disabled}
                    />
                ))}
            </div>
            <ErrorDialog
                ref={errorDialogRef}
                title="Memory Limit Reached"
                message="The local memory limit has been reached. Please delete a chat to free up memory."
            />
            <TwoButtonDialog 
                ref={versionDialogRef}
                title="Update Required"
                message="Your version of Co-op GPT is out of date, please refresh the page to get the latest version. Don't worry, your saved chats will still be available, but you will not be able to use Co-op GPT until you refresh."
                callback={handleVersionUpdateRequired}
                cancelText="Give me a sec"
                confirmText="Refresh Now"
            />
        </div>
    );
};

export default Landing;
